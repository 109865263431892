import React, { useEffect, lazy, Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import useAnalytics from "./analytics/useAnalytics";
import "./reset.css";
import "./App.scss";

import Assessment from "./Pages/Assessment/Assessment";
import Home from "./Pages/Home/Home";
import SmallScreenView from "./Pages/SmallScreenView/SmallScreenView";
import Dashboard from "./Pages/Dashboard/Dashboard";

const Interview = lazy(() => import("./Pages/Interview/Interview"));

function App() {
  const candidateId = useSelector((state) => state.user.id);
  const isLargeScreen = useMediaQuery({ minWidth: 1000 });
  const location = useLocation();
  const { logPageView } = useAnalytics();

  useEffect(() => {
    logPageView(location.pathname);
  }, [location]);

  return (
    <div className="App">
      {isLargeScreen ? (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/interview"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <Interview />
              </Suspense>
            }
          />
          <Route path="/assessment" element={<Assessment />} />
          <Route path="/dashboard" element={<Dashboard />} />
        </Routes>
      ) : (
        <SmallScreenView />
      )}
    </div>
  );
}

export default App;
