import { useSelector, useDispatch } from "react-redux";
import "./Assessment.scss";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import axios from "axios";
import { updateUser } from "../../ducks/slices/userSlice";
import TopNav from "../../Components/TopNav/TopNav";
import useAnalytics from '../../analytics/useAnalytics';

const Assessment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const assessment = useSelector((state) => state.assessment);
  const { logEvent } = useAnalytics();

  useEffect(() => {
    checkPrivacy();
    logEvent('Assessment', 'View Assessment');
  }, [logEvent]);

  const checkPrivacy = async () => {
    try {
      const res = await axios.get(`/auth/getUser`);
      dispatch(
        updateUser({
          userId: res.data.user_id,
          username: res.data.username,
          email: res.data.email,
          firstName: res.data.first_name,
          lastName: res.data.last_name,
          githubId: res.data.github_id,
          linkedinId: res.data.linkedin_id,
        })
      );
    } catch {
      navigate("/");
    }
  };

  const chartData = assessment.categories.map((category) => ({
    name: category.name,
    score: category.score,
  }));

  return (
    <div className="assessment-page">
      <TopNav />
      <div className="assessment-screen">
        <h1 className="assessment-title">Candidate Assessment</h1>

        <div className="overall-score">
          <h2>Overall Score: {assessment.overallScore || "N/A"}/10</h2>
          <p>
            {assessment.overallFeedback || "No overall feedback available."}
          </p>
        </div>

        <div className="category-scores">
          <h2>Category Scores</h2>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis domain={[0, 10]} />
              <Tooltip />
              <Legend />
              <Bar dataKey="score" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        <div className="detailed-feedback">
          <h2>Detailed Feedback</h2>
          {assessment.categories.map((category, index) => (
            <div key={index} className="category-feedback">
              <div className="category-score">
                <h3>{category.name}</h3>
                <p>Score: {category.score || "N/A"}/10</p>
              </div>
              <div className="category-feedback-text">
                <h4>Feedback</h4>
                <p>
                  {category.feedback ||
                    "No feedback available for this category."}
                </p>
              </div>
              <div className="category-checkpoint">
                <h4>Checkpoint</h4>
                <p>
                  {category.reference || "No checkpoint available for this category."}
                </p>
              </div>
            </div>
          ))}
        </div>

        <div className="strengths">
          <h2>Strengths</h2>
          {assessment.strengths && assessment.strengths.length > 0 ? (
            <ul>
              {assessment.strengths.map((strength, index) => (
                <li key={index}>{strength}</li>
              ))}
            </ul>
          ) : (
            <p>No strengths listed.</p>
          )}
        </div>

        <div className="improvement-areas">
          <h2>Areas for Improvement</h2>
          {assessment.areasForImprovement &&
          assessment.areasForImprovement.length > 0 ? (
            <ul>
              {assessment.areasForImprovement.map((area, index) => (
                <li key={index}>{area}</li>
              ))}
            </ul>
          ) : (
            <p>No areas for improvement listed.</p>
          )}
        </div>

        <div className="final-recommendation">
          <h2>Final Recommendation</h2>
          <p>
            {assessment.finalRecommendation ||
              "No final recommendation available."}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Assessment;