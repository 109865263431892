import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  VStack,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import axios from "axios";

const GithubUsernameModal = ({ isOpen, onClose }) => {
  const [githubUsername, setGithubUsername] = useState("");

  const user = useSelector((state) => state.user);

  const handleSubmit = async () => {
    console.log("Submitting GitHub username:", githubUsername);
    // Get user's github id from github api
    const res = await axios.get(`https://api.github.com/users/${githubUsername}`);
    const githubId = res.data.id;
    try {
      const res = await axios.post("/auth/updateUser", {
        username: user.username,
        email: user.email,
        first_name: user.firstName,
        last_name: user.lastName,
        github_id: githubId,
      });
      onClose();
    } catch (err) {
      console.error(err);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
      setGithubUsername("");
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="#111621">
        <ModalHeader>Connect GitHub</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <Input
              placeholder="Enter your GitHub username"
              value={githubUsername}
              onChange={(e) => setGithubUsername(e.target.value)}
              onKeyPress={handleKeyPress} // Add this line
            />
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button bg="#13aa52" color="white" mr={3} onClick={handleSubmit}>
            Connect
          </Button>
          <Button bg="#cb0000" color="white" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default GithubUsernameModal;
