import React from "react";
import { Box, Grid, GridItem } from "@chakra-ui/react";
import TopNav from "../../Components/TopNav/TopNav";
import UserInfoCard from "../../Components/UserInfoCard/UserInfoCard";
import GithubCard from "../../Components/GithubCard/GithubCard";
import "./Dashboard.scss"; // Import the SCSS file

const Dashboard = () => {
  return (
    <Box width="100vw" height="100vh" className="dashboard-container">
      <TopNav />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="flex-start"
        w="100%"
        h="calc(100% - 120px)"
      >
        <Grid
          templateColumns={{
            base: "1fr",
            md: "repeat(3, 1fr)",
          }}
          gap={6}
          mt={8}
          p="5"
        >
          <GridItem>
            <UserInfoCard />
          </GridItem>
          <GridItem colSpan={2}>
            <GithubCard />
          </GridItem>
        </Grid>
      </Box>
    </Box>
  );
};

export default Dashboard;
