import "./Home.scss";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AuthModal from "../../Components/AuthModal/AuthModal";
import TopNav from "../../Components/TopNav/TopNav";
import useAnalytics from '../../analytics/useAnalytics';

const Home = () => {
  const [displayModal, setDisplayModal] = useState(false);
  const [authModalState, setAuthModalState] = useState("login");
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const { logEvent } = useAnalytics();

  const handleStartInterview = () => {
    if (user && user.username) {
      navigate("/interview");
    } else {
      setDisplayModal(true);
    }
    logEvent('Home', 'Click Start Interview');
  };

  return (
    <div className="home-page">
      {displayModal && (
        <AuthModal
          setDisplayModal={setDisplayModal}
          authModalState={authModalState}
          setAuthModalState={setAuthModalState}
        />
      )}
      <TopNav
        setDisplayModal={setDisplayModal}
        setAuthModalState={setAuthModalState}
      />
      <div className="body">
        <p id="product-name">Bayesient AI</p>
        <p id="tagline">Your Personal AI Mock Interviewer</p>
        <div className="description-container">
          <p id="product-description">
            Prepare for your next technical interview with our AI-driven mock interviews. Practice coding challenges, receive instant feedback, and improve your skills to ace your interviews.
          </p>
          <button
            id="start-interview-button"
            onClick={handleStartInterview}
          >
            Start Interview
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;