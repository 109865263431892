import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  VStack,
  Heading,
  Text,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "@chakra-ui/react";
import { FaGithub } from "react-icons/fa";
import GithubIntegrationBg from "../../assets/Github-integration-bg.png";
import GithubUsernameModal from "../GithubUsernameModal/GithubUsernameModal";

const GithubCard = () => {
  const user = useSelector((state) => state.user);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleConnectGitHub = () => {
    setIsModalOpen(true);
  };

  // Return empty div if user.githubId is defined
  if (user.githubId) {
    return <div></div>;
  }

  return (
    <>
      <Card
        w="100%"
        h="100%"
        borderWidth="2px"
        borderRadius="lg"
        overflow="hidden"
        color="gray.800"
        bgColor="white"
        boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
        position="relative"
      >
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          backgroundImage={`url(${GithubIntegrationBg})`}
          backgroundSize="contain"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          opacity="0.4"
          zIndex="0"
        />
        <Box
          position="relative"
          zIndex="1"
          display="flex"
          justifyContent="center"
          alignItems="center"
          w="100%"
          h="100%"
        >
          <Button
            bg="black"
            color="white"
            _hover={{ bg: "gray.600" }}
            onClick={handleConnectGitHub}
          >
            <FaGithub />
            <Text ml={2}>Connect GitHub</Text>
          </Button>
        </Box>
      </Card>
      <GithubUsernameModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  );
};

export default GithubCard;
