import { useState, useEffect } from "react";
import "./AuthModal.scss";
import assert from "assert";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateUser } from "../../ducks/slices/userSlice";
import useAnalytics from "../../analytics/useAnalytics";
import { Button, Icon, Divider, Text } from "@chakra-ui/react";
import { FaGithub, FaLinkedin } from "react-icons/fa";

const AuthModal = ({ authModalState, setAuthModalState, setDisplayModal }) => {
  assert(authModalState === "login" || authModalState === "register");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { logEvent } = useAnalytics();

  const register = async () => {
    if (
      !username ||
      !password ||
      !firstName ||
      !lastName ||
      !confirmPassword ||
      !email
    ) {
      alert("Please fill in all fields");
      return;
    }
    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }
    try {
      const res = await axios.post(`/auth/register`, {
        username,
        email,
        password,
        first_name: firstName,
        last_name: lastName,
      });
      setPassword("");
      setConfirmPassword("");
      dispatch(
        updateUser({
          username: res.data.username,
          email: res.data.email,
          firstName: res.data.first_name,
          lastName: res.data.last_name,
          githubId: res.data.github_id,
          linkedinId: res.data.linkedin_id,
        })
      );
      navigate("/interview");
      logEvent("Auth", "Register", "Success");
    } catch (error) {
      logEvent("Auth", "Register", "Failed");
      // Handle error
    }
  };

  const login = async () => {
    if (!username || !password) {
      alert("Please fill in all fields");
      return;
    }
    try {
      const res = await axios.post(`/auth/login`, { username, password });
      setPassword("");
      dispatch(
        updateUser({
          username: res.data.username,
          email: res.data.email,
          firstName: res.data.first_name,
          lastName: res.data.last_name,
          githubId: res.data.github_id,
          linkedinId: res.data.linkedin_id,
        })
      );
      navigate("/interview");
      logEvent("Auth", "Login", "Success");
    } catch (err) {
      logEvent("Auth", "Login", "Failed");
      // Handle error
      console.log(err);
      // alert(err.response.data);
    }
  };

  const handleGithubLogin = () => {
    logEvent("Auth", "Github Login", "Clicked");
    const state = "github"; // State parameter to identify the provider
    window.location.assign(
      `https://github.com/login/oauth/authorize?client_id=${process.env.REACT_APP_GITHUB_CLIENT_ID}&state=${state}`
    );
  };

  const handleLinkedinLogin = () => {
    logEvent("Auth", "LinkedIn Login", "Clicked");
    const state = "linkedin"; // State parameter to identify the provider
    window.location.assign(
      `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${
        process.env.REACT_APP_LINKEDIN_CLIENT_ID
      }&redirect_uri=${encodeURIComponent(
        process.env.REACT_APP_LINKEDIN_REDIRECT_URI
      )}&state=${state}&scope=openid%20profile%20email`
    );
  };

  const authModalContent = () => {
    if (authModalState === "login") {
      return (
        <>
          <p className="modal-header">Login</p>
          <div className="input-container">
            <p id="input-label-text">Username or Email</p>
            <input
              type="text"
              id="input-box"
              value={username}
              onChange={(event) => {
                setUsername(event.target.value);
                console.log(username);
              }}
            />
          </div>
          <div className="input-container">
            <p id="input-label-text">Password</p>
            <input
              type="password"
              id="input-box"
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
            />
          </div>
          <div className="button-tray">
            <button id="green-button" onClick={login}>
              Sign In
            </button>
            <button
              id="purple-button"
              onClick={() => {
                setAuthModalState("register");
              }}
            >
              New here? Create Account
            </button>
          </div>
          <Divider my={4} borderColor="gray.500" />
          <Button
            leftIcon={<Icon as={FaGithub} />}
            bg="white"
            color="black"
            variant="solid"
            width="100%"
            height="60px"
            _hover={{ bg: "gray.200" }}
            onClick={handleGithubLogin}
            mb={4} // Add margin-bottom for spacing
          >
            Continue with GitHub
          </Button>
          <Button
            leftIcon={<Icon as={FaLinkedin} />}
            bg="#0077B5"
            color="white"
            variant="solid"
            width="100%"
            height="60px"
            _hover={{ bg: "#006699" }}
            onClick={handleLinkedinLogin}
          >
            Continue with LinkedIn
          </Button>
        </>
      );
    } else if (authModalState === "register") {
      return (
        <>
          <p className="modal-header">Create an account</p>
          <div className="name-container">
            <div className="input-container half-width">
              <p id="input-label-text">First Name</p>
              <input
                type="text"
                id="input-box"
                value={firstName}
                onChange={(event) => {
                  setFirstName(event.target.value);
                }}
              />
            </div>
            <div className="input-container half-width">
              <p id="input-label-text">Last Name</p>
              <input
                type="text"
                id="input-box"
                value={lastName}
                onChange={(event) => {
                  setLastName(event.target.value);
                }}
              />
            </div>
          </div>
          <div className="input-container">
            <p id="input-label-text">Username</p>
            <input
              type="text"
              id="input-box"
              value={username}
              onChange={(event) => {
                setUsername(event.target.value);
              }}
            />
          </div>
          <div className="input-container">
            <p id="input-label-text">Email</p>
            <input
              type="email"
              id="input-box"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
          </div>
          <div className="input-container">
            <p id="input-label-text">Password</p>
            <input
              type="password"
              id="input-box"
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
            />
          </div>
          <div className="input-container">
            <p id="input-label-text">Confirm Password</p>
            <input
              type="password"
              id="input-box"
              value={confirmPassword}
              onChange={(event) => {
                setConfirmPassword(event.target.value);
              }}
            />
          </div>
          <div className="button-tray">
            <button id="purple-button" onClick={register}>
              Create Account
            </button>
            <button
              id="green-button"
              onClick={() => {
                setAuthModalState("login");
              }}
            >
              Have an account? Sign In
            </button>
          </div>
          <Divider my={4} borderColor="gray.500" />
          <Button
            leftIcon={<Icon as={FaGithub} />}
            bg="white"
            color="black"
            variant="solid"
            width="100%"
            height="60px"
            _hover={{ bg: "gray.200" }}
            mb={4}
            onClick={handleGithubLogin}
          >
            Continue with GitHub
          </Button>
          <Button
            leftIcon={<Icon as={FaLinkedin} />}
            bg="#0077B5"
            color="white"
            variant="solid"
            width="100%"
            height="60px"
            _hover={{ bg: "#006699" }}
            onClick={handleLinkedinLogin}
          >
            Continue with LinkedIn
          </Button>
        </>
      );
    }
  };

  return (
    <div
      className="auth-modal-container"
      onClick={() => {
        setDisplayModal(false);
      }}
    >
      <div
        className="auth-modal"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        {authModalContent()}
      </div>
    </div>
  );
};

export default AuthModal;