import React from 'react';
import './SmallScreenView.scss';

const SmallScreenView = () => {
  return (
    <div className="small-screen-view">
      <div className="content">
        <h1>Bayesient AI</h1>
        <p className="message">We currently only support larger screens.</p>
        <p className="demo-text">Meanwhile, here's a demo of our product!</p>
        <div className="video-container">
          <iframe
            src="https://www.youtube.com/embed/6rD_uCMTu7E?autoplay=1&fs=1"
            title="Bayesient AI Demo"
            style={{ border: 'none' }}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default SmallScreenView;