import React from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  VStack,
  Heading,
  Text,
  Card,
  CardHeader,
  CardBody,
  Avatar,
} from '@chakra-ui/react';

const UserInfoCard = () => {
  const user = useSelector((state) => state.user);

  return (
    <Card 
      w="md" 
      borderWidth="2px" 
      borderRadius="lg" 
      overflow="hidden" 
      bg="white" 
      color="gray.800"
      boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
    >
      <CardHeader>
        <Avatar size="xl" name={`${user.firstName} ${user.lastName}`} src="" />
        <Heading size="lg" mt={4}>
          {user.firstName} {user.lastName}
        </Heading>
      </CardHeader>
      <CardBody>
        <VStack spacing={3} align="start">
          <Box>
            <Text fontWeight="bold">Username:</Text>
            <Text>{user.username}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Email:</Text>
            <Text>{user.email}</Text>
          </Box>
        </VStack>
      </CardBody>
    </Card>
  );
};

export default UserInfoCard;
